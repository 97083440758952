import { FC, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { connect } from "react-redux";
import { useAuthenticationService } from "ieso-auth0";
import { getIsVideoSession } from "../store/chatconnection/selectors";

const DEFAULT_TIMEOUT = 1_200_000;
const IdleDetectorComponent: FC<{
  timeout: number;
  isVideoSession?: boolean;
}> = ({ timeout = DEFAULT_TIMEOUT, isVideoSession = false }) => {
  const { logout, refresh } = useAuthenticationService();

  useEffect(() => {
    const timerId = setInterval(() => refresh, timeout);
    return () => {
      clearInterval(timerId);
    };
  }, [timeout, refresh]);

  const onIdle = () => {
    logout();
  };

  const { start, pause } = useIdleTimer({
    onIdle,
    timeout,
    events: ["mousemove", "keydown", "mousedown", "visibilitychange", "focus"],
    eventsThrottle: 200,
    element: document,
    crossTab: true,
    name: "idle-timer",
    syncTimers: 200,
    leaderElection: true
  });

  useEffect(() => {
    if (isVideoSession) {
      pause();
    } else {
      start();
    }
  }, [isVideoSession, start, pause]);

  return null;
};

const mapStateToProps = (state) => {
  const isVideoSession = getIsVideoSession(state);

  return {
    isVideoSession
  };
};

export const IdleDetector = connect(mapStateToProps)(IdleDetectorComponent);
