/* eslint-disable */
import { APP_UNLOADING } from "store/global/types";

import { combineReducers } from "redux";
import sanitizeMessage from "utils/sanitize-message";
import {
  CHAT_SET_MESSAGE_HISTORY,
  CHAT_SET_TIMELINE_HISTORY,
  CHAT_SET_CURRENT_MESSAGE,
  TSS_SET_SESSION_INFO,
  UPDATE_SESSION_STATUS,
  UPDATE_SESSION_START_DATE,
  CHAT_SANITIZED_INCOMING_MESSAGE,
  FINISH_SESSION_PATIENT,
  FINISH_SESSION_THERAPIST,
  FINISH_SESSION_THERAPIST_REDIRECT,
  UPDATE_CONNECTION_STATUS,
  REMOVE_RECONNECT_BUTTON,
  USER_IS_TYPING,
  USER_IS_NOT_TYPING,
  SIGNAL_R_SEND_MESSAGE,
  SIGNAL_R_CHECK_SENT_MESSAGE,
  SIGNAL_R_MESSAGE_FAILED_CHECK,
  SESSION_CLOSED,
  FIRST_TIME_CONNECTED,
  DISPLAY_RECONNECTED,
  CHAT_SET_APPOINTMENT_INFO,
  INVALID_SESSION_INFO,
  VALID_SESSION_INFO,
  SET_SESSION_AVAILABILITY,
  UPDATE_IS_SESSION_ACTIVE,
  SET_ADDING_PARTICIPANT_TO_SESSION_STATE,
  SET_HAS_CLINICIANS,
  SET_READONLY_MODE
} from "./types";

export function message_history(state = {}, action = {}) {
  switch (action && action.type) {
    case CHAT_SET_MESSAGE_HISTORY: {
      const new_state = Object.assign({}, state);

      if (action.data) {
        Object.keys(action.data).forEach((xx) => {
          if (action.data[xx].event_type === "Sentence")
            new_state[xx] = {
              ...state[xx],
              ...action.data[xx]
            };
        });
        return new_state;
      }
      break;
    }
    case CHAT_SET_TIMELINE_HISTORY: {
      if (action.data) {
        return action.data;
      }
      break;
    }
    case SIGNAL_R_SEND_MESSAGE: {
      if (action.content && action.model && action.model.event_guid) {
        const new_state = Object.assign({}, state, {
          [action.model.event_guid]: {
            ...action,
            ...action.model,
            event_type: action.eventType || "Sentence",
            transit_status: "maybe",
            sanitizedContent: sanitizeMessage(action.content)
          }
        });

        return new_state;
      }
      break;
    }
    case SIGNAL_R_MESSAGE_FAILED_CHECK: {
      if (
        action.event_guid &&
        state[action.event_guid] &&
        state[action.event_guid].transit_status !== "sent"
      ) {
        const new_state = Object.assign({}, state, {
          [action.event_guid]: {
            ...{},
            ...state[action.event_guid],
            transit_status: "failed"
          }
        });
        return new_state;
      }
      break;
    }
    case SIGNAL_R_CHECK_SENT_MESSAGE: {
      if (
        action.event_guid &&
        state[action.event_guid] &&
        state[action.event_guid].transit_status === "maybe"
      ) {
        const new_state = Object.assign({}, state, {
          [action.event_guid]: {
            ...{},
            ...state[action.event_guid],
            transit_status: "sending"
          }
        });
        return new_state;
      }
      break;
    }
    case CHAT_SANITIZED_INCOMING_MESSAGE: {
      if (action.model && action.model.event_guid) {
        const new_state = Object.assign({}, state, {
          [action.model.event_guid]: {
            ...{},
            ...state[action.model.event_guid],
            ...action.model,
            transit_status: "sent"
          }
        });
        return new_state;
      }
      break;
    }
    case REMOVE_RECONNECT_BUTTON: {
      if (action.time_utc) {
        const new_value = Object.assign({}, state[action.time_utc], {
          reconnect_button: false
        });

        return Object.assign({}, state, {
          [action.event_guid]: new_value
        });
      }
      break;
    }
    default:
  }
  return state;
}
const messagesByDateSent = (aa, bb) => {
  return aa.time - bb.time;
};

const addMessagesFromObject = (state = [], newMessages = {}) => {
  let guid_map = {};
  state.forEach((xx) => {
    guid_map[xx.event_guid] = xx;
  });

  Object.values(newMessages).forEach((xx) => {
    if (newMessages[xx.event_guid].event_type === "Sentence") {
      guid_map[xx.event_guid] = {
        event_guid: xx.event_guid,
        time: new Date(xx.time_utc)
      };
    }
  });

  const newState = Object.values(guid_map).sort(messagesByDateSent);
  return newState;
};

export function message_order(state = [], action = {}) {
  switch (action && action.type) {
    case CHAT_SET_MESSAGE_HISTORY: {
      if (action.data) {
        const newState = addMessagesFromObject(state, action.data);
        return newState;
      }
      break;
    }
    case SIGNAL_R_SEND_MESSAGE: {
      if (action.content && action.model && action.model.event_guid) {
        return addMessagesFromObject(state, {
          [action.model.event_guid]: {
            ...action,
            ...action.model,
            event_type: action.eventType || "Sentence"
          }
        });
      }
      break;
    }
    case CHAT_SANITIZED_INCOMING_MESSAGE: {
      if (action.model && action.model.event_guid) {
        return addMessagesFromObject(state, {
          [action.model.event_guid]: {
            ...action,
            ...action.model,
            event_type: action.event_type || "Sentence"
          }
        });
      }
      break;
    }
    default:
  }
  return state;
}

export function current_message(state = "", action = {}) {
  switch (action.type) {
    case CHAT_SET_CURRENT_MESSAGE:
      if (action.value !== undefined) {
        return action.value;
      }
      break;
    default:
  }
  return state;
}

export function enter_to_send(state = true, action = {}) {
  return state;
}

export function session_not_found(state = false, action = {}) {
  switch (action.type) {
    case INVALID_SESSION_INFO:
      return true;
    case VALID_SESSION_INFO:
      return false;
    default:
  }
  return state;
}

export function session_availability(state = "", action = {}) {
  switch (action.type) {
    case SET_SESSION_AVAILABILITY:
      return action.data;
    default:
  }
  return state;
}

export function has_clinicians(state = false, action = {}) {
  switch (action.type) {
    case SET_HAS_CLINICIANS:
      return action.data;
    default:
  }
  return state;
}

export function readonly_mode(state = false, action = {}) {
  switch (action.type) {
    case SET_READONLY_MODE:
      return action.data;
    default:
  }
  return state;
}

export function adding_participant_to_session_state(
  state = { loading: false, error: null },
  action = {}
) {
  switch (action.type) {
    case SET_ADDING_PARTICIPANT_TO_SESSION_STATE:
      return { ...state, ...action.data };
    default:
  }
  return state;
}

export function session_guid(state = "", action = {}) {
  switch (action.type) {
    case TSS_SET_SESSION_INFO:
      if (action.data && action.data.session_guid) {
        return action.data.session_guid;
      }
      break;
    default:
  }
  return state;
}

export function start_date_utc(state = "", action = {}) {
  switch (action.type) {
    case TSS_SET_SESSION_INFO:
      if (action.data && action.data.start_date_utc) {
        return action.data.start_date_utc;
      }
      break;
    case UPDATE_SESSION_START_DATE:
      if (state === "" && action.data) return action.data;
      break;
    default:
  }
  return state;
}

export function end_date_utc(state = "", action = {}) {
  switch (action.type) {
    case TSS_SET_SESSION_INFO:
      if (action.data) {
        return action.data.end_date_utc;
      }
      break;
    default:
  }
  return state;
}

export function session_modality(state = "", action = {}) {
  switch (action.type) {
    case TSS_SET_SESSION_INFO:
      if (action.data) {
        return action.data.modality || "";
      }
      break;
    default:
  }
  return state;
}

export function is_session_active(state = false, action = {}) {
  switch (action.type) {
    case TSS_SET_SESSION_INFO:
      if (action.data) {
        return action.data.is_active || false;
      }
      break;
    case UPDATE_IS_SESSION_ACTIVE:
      return action.value;
      break;
    default:
  }
  return state;
}

export function participants(state = {}, action = {}) {
  switch (action.type) {
    case CHAT_SET_APPOINTMENT_INFO:
      if (action.data && action.data.participants) {
        const new_state = {};
        action.data.participants.forEach((xx) => {
          new_state[xx.identity_guid] = xx;
        });
        return new_state;
      }
      break;
    case TSS_SET_SESSION_INFO: {
      // merge participant data
      const participant_data = action.data && action.data.participants;
      if (participant_data) {
        const new_state = JSON.parse(JSON.stringify(state));
        participant_data.forEach((xx, ii) => {
          // convert array to object
          if (new_state[xx.identity_guid]) {
            new_state[xx.identity_guid] = Object.assign(
              new_state[xx.identity_guid],
              xx,
              { is_typing: false }
            );
          } else {
            new_state[xx.identity_guid] = xx;
          }
        });
        return new_state;
      }
      break;
    }
    case UPDATE_SESSION_STATUS: {
      const participant_data = action.data && action.data.participants;
      if (participant_data) {
        const new_state = JSON.parse(JSON.stringify(state));
        participant_data.forEach((xx, ii) => {
          // convert array to object
          if (new_state[xx.identity_guid]) {
            new_state[xx.identity_guid] = Object.assign(
              new_state[xx.identity_guid],
              xx,
              { is_typing: false }
            );
          } else {
            new_state[xx.identity_guid] = xx;
          }
        });
        return new_state;
      }
      break;
    }
    case USER_IS_TYPING: {
      const new_state = JSON.parse(JSON.stringify(state));
      if (new_state[action.identityGuid]) {
        new_state[action.identityGuid].is_typing = true;
      }
      return new_state;
    }
    case USER_IS_NOT_TYPING: {
      const new_state = JSON.parse(JSON.stringify(state));
      if (new_state[action.identityGuid]) {
        new_state[action.identityGuid].is_typing = false;
      }
      return new_state;
    }
    default:
  }
  return state;
}

export function session_in_progress_status(state = "", action = {}) {
  switch (action.type) {
    case TSS_SET_SESSION_INFO:
      if (action.data.end_date_utc) {
        return "finished";
      } else if (action.data.start_date_utc) {
        return "started";
      } else return "pending";
      return state;

    case UPDATE_SESSION_START_DATE:
      return "started";
    case FINISH_SESSION_THERAPIST:
      return "finished_feedback_required";
    case FINISH_SESSION_PATIENT:
      return "finished_redirect_patient";
    case FINISH_SESSION_THERAPIST_REDIRECT:
      return "finished_redirect_therapist";
    case SESSION_CLOSED:
      return "closed";
    default:
  }
  return state;
}

export function connection_status(state = "None", action = {}) {
  switch (action.type) {
    case UPDATE_CONNECTION_STATUS:
      return action.value ? action.value : state;
    default:
  }
  return state;
}

export function reconnected_status(state = false, action = {}) {
  switch (action.type) {
    case DISPLAY_RECONNECTED:
      return action.value;
    default:
  }
  return state;
}

export function has_connected_before(state = false, action = {}) {
  switch (action.type) {
    case FIRST_TIME_CONNECTED:
      return true;
    default:
  }
  return state;
}

export function app_is_unloading(state = false, action = {}) {
  switch (action.type) {
    case APP_UNLOADING:
      return true;
    default:
  }
  return state;
}

export const reducer = combineReducers({
  session_in_progress_status,
  session_not_found,
  session_guid,
  start_date_utc,
  end_date_utc,
  session_modality,
  session_availability,
  has_clinicians,
  is_session_active,
  adding_participant_to_session_state,
  participants,
  enter_to_send,
  message_history,
  message_order,
  current_message,
  connection_status,
  reconnected_status,
  has_connected_before,
  app_is_unloading,
  readonly_mode
});

export default reducer;
