/* eslint-disable camelcase */
import React, { FC, useCallback } from "react";
import { IMessageAttachment } from "AtlasChat/types";
import Attachment from "AtlasChat/components/Attachment/Attachment.tsx";
import { AttachmentButton } from "./styles.ts";

interface IMessageAttachmentProps {
  // eslint-disable-next-line react/no-unused-prop-types
  key: string;
  attachment: IMessageAttachment;
  onDownloadAttachment: (
    attachment: IMessageAttachment,
    shouldSave?: boolean
  ) => Promise<Blob>;
}

const MessageAttachment: FC<IMessageAttachmentProps> = ({
  attachment,
  onDownloadAttachment
}) => {
  const { id, file_name, content_type } = attachment;

  const handleAttachmentView = useCallback(
    () =>
      onDownloadAttachment(attachment, false).then((blob) => {
        window.open(
          URL.createObjectURL(new Blob([blob], { type: content_type })),
          "_blank"
        );
      }),
    [attachment]
  );

  return (
    <Attachment
      key={id}
      title="Attachment"
      fileName={file_name}
      fileType={content_type}
      mb={2}
    >
      <AttachmentButton
        ariaLabel="View attachment"
        ariaDescription="View an attachment"
        role="link"
        appearance="link"
        mr={4}
        onClick={handleAttachmentView}
      >
        View
      </AttachmentButton>
      <AttachmentButton
        role="link"
        ariaLabel="Download attachment"
        ariaDescription="Download an attachment"
        appearance="link"
        onClick={() => onDownloadAttachment(attachment)}
      >
        Download
      </AttachmentButton>
    </Attachment>
  );
};

export default React.memo(MessageAttachment);
