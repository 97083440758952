import { useMutation } from "@tanstack/react-query";
import { apiPost } from "ieso-api-helper";
import FileSaver from "file-saver";
import { ICredentials, useCredentials } from "ieso-auth0";
import loadConfig from "config";
import { ISessionDates } from "./components/ChatHeader/helpers.tsx";

const getConfig = async () => loadConfig;

const transcriptUrls = {
  download: (sessionId: string): Promise<string> =>
    getConfig().then(
      (config) =>
        `${config.tss_url}/therapy/v1/sessions/${sessionId}/transcript`
    )
};

async function downloadTranscript(
  sessionId: string,
  session: ISessionDates,
  credentials: ICredentials
) {
  return apiPost(
    await transcriptUrls.download(sessionId),
    {
      transcriptDate: session.time,
      startMarkerGuid: session.start,
      endMarkerGuid: session.end
    },
    credentials
  );
}

export const useDownloadTranscript = (sessionId: string) => {
  const credentials = useCredentials();
  return useMutation({
    mutationFn: (session: ISessionDates) =>
      downloadTranscript(sessionId, session, credentials),
    onSuccess(data: string, variables: ISessionDates) {
      const blob = new Blob([data], {
        type: "text/plain;charset=utf-8"
      });
      FileSaver.saveAs(blob, variables.label);
    }
  });
};
