import {
  RECEIVE_NOTIFY_TYPING,
  UPDATE_SESSION_STATUS,
  SIGNAL_R_RECEIVE_EVENT,
  NOTIFY_CONNECTIONS,
  SIGNAL_R_CONNECTION_CLOSED
} from "../types";

export default (getState, dispatch, signalRMiddlewareState) => {
  signalRMiddlewareState.connection.on("ReceiveEvent", (data) => {
    // fire redux action
    dispatch({
      type: SIGNAL_R_RECEIVE_EVENT,
      model: data
    });
  });
  signalRMiddlewareState.connection.on("UserTyping", (data) => {
    // fire redux action
    dispatch({
      type: RECEIVE_NOTIFY_TYPING,
      model: data
    });
  });
  signalRMiddlewareState.connection.on("NotifyConnections", (data) => {
    // fire redux action
    dispatch({
      type: UPDATE_SESSION_STATUS,
      data
    });
    dispatch({
      type: NOTIFY_CONNECTIONS,
      data
    });
  });
  signalRMiddlewareState.connection.on("AppointmentStarted", () => {
    // fire redux action
  });
  signalRMiddlewareState.connection.on("Completed", () => {
    // fire redux action
  });
  signalRMiddlewareState.connection.onclose(() => {
    // Code to do retry.
    dispatch({ type: SIGNAL_R_CONNECTION_CLOSED });
  });
};
