/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import FileSaver from "file-saver";
import { ICredentials, useCredentials } from "ieso-auth0";
import { useTelemetry, useTrackStopwatch } from "ieso-telemetry";
import {
  apiDelete,
  apiDownload,
  apiPost,
  IHttpProgressEvent
} from "ieso-api-helper";
import { IMessageAttachment } from "AtlasChat/types.ts";
import { BrowserUtils } from "darker-matter";
import { formatSizeInBytes } from "../../utils/formatting.ts";
import apiUrls from "./apiUrls.ts";

interface AddFileMutationParams {
  id: string;
  name: string;
  data: FormData;
  size: number;
  progressHandler: (progressEvent: IHttpProgressEvent) => void;
}

export const useAttachmentsApi = (sessionId: string) => {
  const credentials = useCredentials();
  const { startTimer, stopTimer } = useTrackStopwatch("attachment");
  const telemetry = useTelemetry();

  const addFileMutation = useMutation({
    mutationFn: ({
      id,
      name,
      data,
      size,
      progressHandler
    }: AddFileMutationParams) => {
      startTimer("upload");
      return postAttachment({
        sessionId,
        id,
        data,
        size,
        credentials,
        progressHandler
      })
        .then((result) => {
          telemetry.trackEvent("attachment/uploaded", {
            Size: size
          });
          return result;
        })
        .catch((reason) => {
          telemetry.trackEvent("attachment/failed");
          throw reason;
        })
        .finally(() => {
          stopTimer(false, { id, fileName: name });
        });
    }
  });

  const deleteFileMutation = useMutation({
    mutationFn: (fileId: string) =>
      deleteAttachment(sessionId, fileId, credentials).then((result) => {
        telemetry.trackEvent("attachment/deleted");
        return result;
      })
  });

  return useMemo(
    () => ({
      addFileMutation,
      deleteFileMutation,
      downloadAttachment: (attachment: IMessageAttachment, shouldSave = true) =>
        downloadAttachment(sessionId, attachment, credentials, shouldSave)
    }),
    [sessionId, addFileMutation, deleteFileMutation]
  );
};

type PostAttachmentParamsType = Omit<AddFileMutationParams, "name"> & {
  sessionId: string;
  credentials: ICredentials;
};

async function postAttachment({
  sessionId,
  id,
  data,
  size,
  credentials,
  progressHandler
}: PostAttachmentParamsType) {
  const maximumAttachmentSize = 10485760; // 10MB

  if (maximumAttachmentSize && size >= maximumAttachmentSize) {
    throw new Error(
      `Sorry, we are unable to attach that file as it exceeds the ${formatSizeInBytes(
        maximumAttachmentSize,
        0
      )} attachments size limit.`
    );
  }

  const response = await apiPost<{ readonly id: string }>(
    await apiUrls.add(sessionId),
    data,
    {
      onUploadProgress: progressHandler,
      ...credentials
    }
  );
  return { newId: response.id, oldId: id };
}

async function deleteAttachment(
  sessionId: string,
  fileId: string,
  credentials: ICredentials
) {
  await apiDelete(await apiUrls.delete(sessionId, fileId), credentials);
  return fileId;
}

async function downloadAttachment(
  sessionId: string,
  attachment: IMessageAttachment,
  credentials: ICredentials,
  shouldSave = true
) {
  const url = await apiUrls.download(sessionId, attachment.id);

  const safariVersion = BrowserUtils.getSafariVersion();

  const file = await apiDownload(url, {
    ...credentials,
    accept: attachment.content_type,
    blobType:
      safariVersion && ![11, 12].includes(safariVersion)
        ? "application/octet-stream"
        : attachment.content_type
  });

  if (shouldSave) FileSaver.saveAs(file, attachment.file_name);

  return file;
}
