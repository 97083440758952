/* eslint-disable */
import axios from "axios";

import {
  getMyId,
  getMyName,
  getMyRole,
  getBearerAuthHeader,
  getToken
} from "Identity/selectors";
import { getAppointmentId } from "store/chatendpoints/selectors";
import { getSessionTranscript } from "store/therapyservice/actions";
import { SET_LOADING } from "Loading/data/actions";
import {
  SIGNAL_R_SETUP,
  SIGNAL_R_CONNECT,
  SIGNAL_R_SEND_MESSAGE,
  CHAT_SET_CURRENT_MESSAGE,
  TSS_SET_SESSION_INFO,
  TECHNICAL_DIFFICULTIES,
  SIGNAL_R_NOTIFY_TYPING,
  USER_OFFLINE,
  CHAT_SET_APPOINTMENT_INFO,
  INVALID_SESSION_INFO,
  VALID_SESSION_INFO,
  SET_SESSION_AVAILABILITY,
  SET_HAS_CLINICIANS,
  SET_ADDING_PARTICIPANT_TO_SESSION_STATE,
  SET_READONLY_MODE
} from "./types";
import { postErrorToElastic } from "../logging/actions";
import {
  getMyIdentity,
  getCurrentMessage,
  getSessionId,
  getConnectionStatus
} from "./selectors";
import { SessionAvailability } from "AtlasChat/types";

import loadConfig from "../../config/index";
import { participants } from "store/chatconnection/reducer";
import { apiGet } from "ieso-api-helper";

const uuidv4 = require("uuid/v4");

let config;

export function connectToSignalR() {
  return { type: SIGNAL_R_SETUP };
}

export function reconnectToSignalR() {
  return { type: SIGNAL_R_CONNECT, retries: 3 };
}

const configRequest = loadConfig.then((c) => {
  config = c;
});

export function getSessionInfo() {
  return async (dispatch, getState) => {
    await configRequest;

    let sessionId = getAppointmentId(getState());

    const requestConfig = {
      headers: {
        Authorization: getBearerAuthHeader(getState())
      },
      crossDomain: true
    };

    return axios
      .get(`${config.tss_url}/therapy/v1/sessions/${sessionId}`, requestConfig)
      .then(function (response) {
        dispatch({
          type: TSS_SET_SESSION_INFO,
          data: response.data
        });
        dispatch({
          type: CHAT_SET_APPOINTMENT_INFO,
          data: response.data
        });
        dispatch({
          type: SET_LOADING,
          data: false
        });
      })
      .catch(function (error) {
        dispatch({
          type: INVALID_SESSION_INFO
        });
        dispatch(postErrorToElastic(["getSessionInfo()", error].join(" ")));
        dispatch(technicalDifficulties("getSessionInfo from TSS failed"));
      });
  };
}

export function getAtlasSessionInfo(referralId) {
  return async (dispatch, getState) => {
    await configRequest;

    const state = getState();

    const tssUrl = config.tss_url;
    const requestConfig = {
      headers: {
        Authorization: getBearerAuthHeader(state)
      },
      crossDomain: true
    };

    const roles = getMyRole(state);

    return axios
      .get(
        `${tssUrl}/therapy/v1/sessions/external-referrals/${referralId}`,
        requestConfig
      )
      .then((response) => {
        const myId = getMyId(state);
        const wasJoinedAsClinician = response.data.participants.some(
          (p) => p.identity_guid === myId && p.is_owner && !p.is_removed
        );
        let availability = response.data.participants.some((p) => p.is_owner)
          ? SessionAvailability.Available
          : SessionAvailability.AwaitingClinician;

        if (
          !wasJoinedAsClinician &&
          roles.includes("GlobalAdmin") &&
          roles.includes("Agent")
        ) {
          availability = SessionAvailability.JoinableAsSupervisor;
        }
        const hasClinicians = response.data.participants.some(
          (p) => p.is_owner && !p.is_removed
        );
        dispatch({
          type: SET_SESSION_AVAILABILITY,
          data: availability
        });
        dispatch({
          type: TSS_SET_SESSION_INFO,
          data: response.data
        });
        dispatch({
          type: SET_LOADING,
          data: false
        });
        dispatch({
          type: SET_HAS_CLINICIANS,
          data: hasClinicians
        });

        connectToChatIfNeeded(state, dispatch, response.data);
      })
      .catch((error) => onError(error, dispatch, state));
  };
}

// Do not need to connect to SignalR if chat is read-only
function connectToChatIfNeeded(state, dispatch, sessionData) {
  const myId = getMyId(state);
  const isReadOnlyMode =
    sessionData.end_date_utc ||
    !sessionData.participants.some((p) => p.identity_guid === myId);

  if (!isReadOnlyMode) {
    dispatch({ type: SIGNAL_R_SETUP, skipGetSessionTranscript: true });
  }
}

function onError(error, dispatch, state) {
  const roles = getMyRole(state);
  const errorCode = error?.response?.status;
  const errorText = error?.response?.data?.detail;
  const isPatient =
    roles === undefined || roles.length === 0 || roles.includes("Member");
  let sessionAvailability;

  if (errorCode === 403) {
    if (isPatient) {
      sessionAvailability = SessionAvailability.UnknownPatient;
    } else if (errorText === "Sorry you no longer have access to this chat") {
      sessionAvailability = SessionAvailability.NoLongerHaveAccess;
    } else {
      sessionAvailability = SessionAvailability.Joinable;
    }
  }

  if (errorCode === 404) {
    sessionAvailability = isPatient
      ? SessionAvailability.UnknownPatient
      : SessionAvailability.UnknownClinitian;
  }

  dispatch({
    type: SET_SESSION_AVAILABILITY,
    data: sessionAvailability
  });
  dispatch({
    type: INVALID_SESSION_INFO
  });
  dispatch(postErrorToElastic(["getSessionInfo() ", error].join(" ")));
  dispatch(
    technicalDifficulties(
      "getSessionInfo by external referral id from TSS failed"
    )
  );
}

export function addParticipantToSession(referralId) {
  return async (dispatch, getState) => {
    await configRequest;
    const state = getState();
    const name = getMyName(state);

    const requestConfig = {
      headers: {
        Authorization: getBearerAuthHeader(getState())
      },
      crossDomain: true
    };

    return axios
      .put(
        `${config.tss_url}/therapy/v1/sessions/external-referrals/${referralId}/participant`,
        { name },
        requestConfig
      )
      .then((response) => {
        dispatch({
          type: TSS_SET_SESSION_INFO,
          data: response.data
        });
        dispatch({
          type: SET_SESSION_AVAILABILITY,
          data: "Available"
        });
        dispatch({
          type: VALID_SESSION_INFO
        });
        dispatch({
          type: SET_ADDING_PARTICIPANT_TO_SESSION_STATE,
          data: {
            loading: false,
            error: null
          }
        });

        connectToChatIfNeeded(state, dispatch, response.data);
      })
      .catch((error) => {
        dispatch({
          type: SET_ADDING_PARTICIPANT_TO_SESSION_STATE,
          data: {
            loading: false,
            error: error
          }
        });
        dispatch(
          postErrorToElastic(["addParticipant by referralId", error].join(" "))
        );
      });
  };
}

export function viewSessionAsSupervisor(referralId) {
  return async (dispatch, getState) => {
    await configRequest;
    const state = getState();
    const myId = getMyId(state);
    const token = getToken(getState());
    const url = `${config.tss_url}/therapy/v1/sessions/external-referrals/${referralId}`;

    const credentials = {
      userId: myId,
      token: token
    };

    return apiGet(url, credentials)
      .then((response) => {
        dispatch({
          type: TSS_SET_SESSION_INFO,
          data: response
        });
        dispatch({
          type: SET_SESSION_AVAILABILITY,
          data: "Available"
        });
        dispatch({
          type: VALID_SESSION_INFO
        });
        dispatch({
          type: SET_READONLY_MODE,
          data: true
        });

        connectToChatIfNeeded(state, dispatch, response.data);
      })
      .catch((error) => {
        dispatch(
          postErrorToElastic(
            ["viewAsSupervisor by referralId", error].join(" ")
          )
        );
      });
  };
}

export function notifyTyping() {
  return {
    type: SIGNAL_R_NOTIFY_TYPING
  };
}

export function sendMessage(message, attachments) {
  return (dispatch, getState) => {
    const state = getState();

    const connectionStatus = getConnectionStatus(state);

    const content = message || getCurrentMessage(state);
    if (
      (!content || content === "" || content === "<br />") &&
      !attachments?.length
    ) {
      // don't send empty messages
      return Promise.resolve("");
    }

    const myIdentity = getMyIdentity(state);
    const sessionId = getAppointmentId(state) || getSessionId(state);

    return Promise.all([
      dispatch({
        type: CHAT_SET_CURRENT_MESSAGE,
        value: ""
      }),
      dispatch({
        type: SIGNAL_R_SEND_MESSAGE,
        appointment_id: sessionId,
        content,
        attachments,
        model: {
          participantID: myIdentity && myIdentity.id,
          event_guid: uuidv4(),
          identity_guid: getMyId(getState()),
          time_utc: new Date().toUTCString()
        }
      })
    ]);
  };
}

export function setCurrentMessage(value) {
  return {
    type: CHAT_SET_CURRENT_MESSAGE,
    value
  };
}

export const technicalDifficulties = (errorMessage) => ({
  type: TECHNICAL_DIFFICULTIES,
  value: errorMessage
});

export const userOffline = (errorMessage) => ({
  type: USER_OFFLINE,
  value: errorMessage
});
