import React, { FC } from "react";
import { JoinBtn } from "./styles.ts";

interface IJoinButtonProps {
  connectToCall: () => void;
  mobileView: boolean;
  disabled: boolean;
}

export const JoinButton: FC<IJoinButtonProps> = ({
  connectToCall,
  mobileView,
  disabled
}) => (
  <JoinBtn
    ariaLabel="Connect to video"
    ariaDescription="Connect to video session"
    icon="video"
    magnitude="small"
    onClick={connectToCall}
    disabled={disabled}
  >
    {mobileView ? "Join" : "Connect to video"}
  </JoinBtn>
);

export default JoinButton;
