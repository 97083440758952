export const SIGNAL_R_SETUP = "chatconnection/SIGNAL_R_SETUP";
export const SIGNAL_R_CONNECT = "chatconnection/SIGNAL_R_CONNECT";

export const SIGNAL_R_NOTIFY_TYPING = "chatconnection/SIGNAL_R_NOTIFY_TYPING";
export const SIGNAL_R_NOTIFY_CONNECTIONS =
  "chatconnection/SIGNAL_R_NOTIFY_CONNECTIONS";
export const SIGNAL_R_COMPLETED = "chatconnection/SIGNAL_R_COMPLETED";
export const SIGNAL_R_APPOINTMENT_STARTED =
  "chatconnection/SIGNAL_R_APPOINTMENT_STARTED";
export const SIGNAL_R_SEND_MESSAGE = "chatconnection/SIGNAL_R_SEND_MESSAGE";
export const SIGNAL_R_CHECK_SENT_MESSAGE =
  "chatconnection/SIGNAL_R_CHECK_SENT_MESSAGE";
export const SIGNAL_R_MESSAGE_FAILED_CHECK =
  "chatconnection/SIGNAL_R_MESSAGE_FAILED_CHECK";

export const SIGNAL_R_CONTROL_MESSAGE =
  "chatconnection/SIGNAL_R_CONTROL_MESSAGE";
export const SIGNAL_R_SEND_ACTION = "chatconnection/SIGNAL_R_SEND_ACTION";
export const SIGNAL_R_USER_ONLINE_STATUS =
  "chatconnection/SIGNAL_R_USER_ONLINE_STATUS";
export const JOIN_APPOINTMENT = "chatconnection/Appointment/JOIN_APPOINTMENT";
export const SEND_NOTIFY_TYPING = "chatconnection/SEND_NOTIFY_TYPING";
export const RECEIVE_NOTIFY_TYPING = "chatconnection/RECEIVE_NOTIFY_TYPING";
export const CHAT_SET_CURRENT_MESSAGE =
  "chatconnection/CHAT_SET_CURRENT_MESSAGE";
export const CHAT_SET_MESSAGE_HISTORY = "chatconnection/SET_MESSAGE_HISTORY";
export const CHAT_SET_TIMELINE_HISTORY = "chatconnection/SET_TIMELINE_HISTORY";
export const USER_IS_TYPING = "chatconnection/USER_IS_TYPING";
export const USER_IS_NOT_TYPING = "chatconnection/USER_IS_NOT_TYPING";
export const CHAT_SANITIZED_INCOMING_MESSAGE =
  "chatconnection/SANITIZED_INCOMING_MESSAGE";
export const TSS_SET_SESSION_INFO = "chatconnection/TSS_SET_SESSION_INFO";
export const UPDATE_IS_SESSION_ACTIVE =
  "chatconnection/UPDATE_IS_SESSION_ACTIVE";
export const UPDATE_SESSION_STATUS = "chatconnection/UPDATE_SESSION_STATUS";
export const NOTIFY_CONNECTIONS = "chatconnection/NOTIFY_CONNECTIONS";
export const UPDATE_SESSION_START_DATE =
  "chatconnection/UPDATE_SESSION_START_DATE";
export const SIGNAL_R_RECEIVE_EVENT = "chatconnection/SIGNAL_R_RECEIVE_EVENT";

export const TECHNICAL_DIFFICULTIES = "chatconnection/TECHNICAL_DIFFICULTIES";
export const USER_OFFLINE = "chatconnection/USER_OFFLINE";
export const FINISH_SESSION_PATIENT = "chatconnection/FINISH_SESSION_PATIENT";
export const FINISH_SESSION_THERAPIST =
  "chatconnection/FINISH_SESSION_THERAPIST";
export const FINISH_SESSION_THERAPIST_REDIRECT =
  "chatconnection/FINISH_SESSION_THERAPIST_REDIRECT";
export const SIGNAL_R_CONNECTION_CLOSED =
  "chatconnection/SIGNAL_R_CONNECTION_CLOSED";

export const UPDATE_CONNECTION_STATUS =
  "chatconnection/UPDATE_CONNECTION_STATUS";

export const REMOVE_RECONNECT_BUTTON = "chatconnection/REMOVE_RECONNECT_BUTTON";

export const DISPLAY_RECONNECTED = "chatconnection/DISPLAY_RECONNECTED";
export const FIRST_TIME_CONNECTED = "chatconnection/FIRST_TIME_CONNECTED";
export const USER_RECONNECT = "chatconnection/RECONNECT";
export const SESSION_CLOSED = "chatconnection/SESSION_CLOSED";

export const CHAT_SET_APPOINTMENT_INFO =
  "chatconnection/CHAT_SET_APPOINTMENT_INFO";

export const INVALID_SESSION_INFO = "chatconnection/INVALID_SESSION_INFO";
export const VALID_SESSION_INFO = "chatconnection/ VALID_SESSION_INFO";
export const SET_SESSION_AVAILABILITY =
  "chatconnection/SET_SESSION_AVAILABILITY";
export const SET_ADDING_PARTICIPANT_TO_SESSION_STATE =
  "SET_ADDING_PARTICIPANT_TO_SESSION_STATE";
export const SET_HAS_CLINICIANS = "chatconnection/SET_HAS_CLINICIANS";
export const SET_READONLY_MODE = "chatconnection/SET_READONLY_MODE";
