/* eslint-disable camelcase */
import { combineReducers } from "redux";
import { decode } from "jsonwebtoken";

import { USER_FOUND, reducer as oidc } from "redux-oidc";
import { SET_LOADING_TIMER } from "Identity/actions";

// Reducers
function access_token_decoded(state = {}, action = {}) {
  switch (action.type) {
    case USER_FOUND:
      return decode(action.payload.access_token);
    default:
      return state;
  }
}

function id_token_decoded(state = {}, action = {}) {
  switch (action.type) {
    case USER_FOUND:
      return decode(action.payload.id_token);
    default:
      return state;
  }
}

function userFoundCount(state = 0, action = {}) {
  switch (action.type) {
    case USER_FOUND:
      return state + 1;
    default:
      return state;
  }
}

function loadingTimer(state = false, action = {}) {
  switch (action.type) {
    case SET_LOADING_TIMER:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  oidc,
  access_token_decoded,
  id_token_decoded,
  userFoundCount,
  loadingTimer
});
