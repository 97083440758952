import { createSelector } from "reselect";

import { getMyId } from "Identity/selectors";
import { CONNECTION_STATUS_STRINGS } from "./consts";

export const currentMessage = (state) => state.chatconnection.current_message;

export const messageHistory = (state) => state.chatconnection.message_history;

export const users = (state) => state.chatconnection.participants;

export const getSessionStatus = (state) =>
  state.chatconnection.session_in_progress_status;

export const getConnectionStatus = (state) =>
  state.chatconnection.connection_status;

const isConnected = (state) =>
  getConnectionStatus(state) === CONNECTION_STATUS_STRINGS.Connected;

export const propsIndex = (state, props) => props.index;

// TODO do i need to use real selectors?
export const isReconnected = (state) =>
  isConnected(state) && state.chatconnection.reconnected_status;

export const isUnloading = (state) => state.chatconnection.app_is_unloading;

export const getSessionId = (state) => state.chatconnection.session_guid;

export const getSessionEndDate = (state) => state.chatconnection.end_date_utc;

export const isValidSession = (state) => state.chatconnection.session_not_found;

export const getSessionAvailability = (state) =>
  state.chatconnection.session_availability;

export const getHasClinicians = (state) => state.chatconnection.has_clinicians;

export const getIsSessionActive = (state) =>
  state.chatconnection.is_session_active;

export const getAddingParticipantState = (state) =>
  state.chatconnection.adding_participant_to_session_state;

export const hasConnectedBefore = (state) =>
  (state.chatconnection && state.chatconnection.has_connected_before) || false;

export const shouldShowConnectionStatus = (state) =>
  !isUnloading(state) &&
  (!isConnected(state) || (hasConnectedBefore(state) && isReconnected(state)));

export const shouldShowReconnectButton = (state) =>
  getConnectionStatus(state) === CONNECTION_STATUS_STRINGS.Disconnected;

export const getOtherUsersIds = createSelector(
  [users, getMyId],
  (ids, filterId) => {
    const otherUsersIds = Object.keys(ids).filter((user) => user !== filterId);
    return otherUsersIds;
  }
);

export const getOtherUserTypingStatus = createSelector(
  [users, getOtherUsersIds],
  (sUsers, userIds) => {
    const userTypingStatus = sUsers[userIds[0]] && sUsers[userIds[0]].is_typing;
    if (!userTypingStatus) {
      return false;
    }
    return userTypingStatus;
  }
);

export const getMessageHistory = createSelector(
  [messageHistory, users, getOtherUsersIds, getOtherUserTypingStatus],
  (messages, allUsers, otherUsersIds, otherUserIsTyping) => {
    if (!messages) {
      return { messages: [], typingMessage: null };
    }
    const newMessages = Object.values(messages).slice();
    const firstChunk = newMessages.slice(0, -10);
    const lastChunk = newMessages.slice(-10);
    lastChunk.sort((a, b) => {
      const aDate = a.recordingStartedUtc ?? a.time_utc;
      const bDate = b.recordingStartedUtc ?? b.time_utc;
      return aDate.localeCompare(bDate);
    });
    const sortedHistory = [...firstChunk, ...lastChunk];

    if (otherUserIsTyping) {
      const otherUser = allUsers[otherUsersIds[0]];

      const typingMessage = {
        event_guid: "typing",
        event_type: "typing",
        identity_guid: otherUser.identity_guid,
        name: otherUser.fullname,
        sanitizedContent: "...",
        time_utc: new Date() // format
      };
      return { messages: sortedHistory, typingMessage };
    }
    return { messages: sortedHistory, typingMessage: null };
  }
);

export const getMessageHistoryInOrder = createSelector(
  [messageHistory, users, getOtherUsersIds, getOtherUserTypingStatus],
  (messages, allUsers, otherUsersIds, otherUserIsTyping) => {
    if (!messages) {
      return [];
    }
    const newMessages = Object.values(messages).slice();

    if (otherUserIsTyping) {
      const otherUser = allUsers[otherUsersIds[0]];

      newMessages.push({
        event_guid: "typing",
        event_type: "typing",
        identity_guid: otherUser.identity_guid,
        name: otherUser.fullname,
        sanitizedContent: "...",
        time_utc: new Date() // format
      });
    }
    return newMessages;
  }
);

export const getMessageHistoryKeyMap = createSelector(
  [getMessageHistoryInOrder],
  (messageHistoryInOrder) => {
    if (!messageHistoryInOrder) {
      return [];
    }

    const keyMap = {};
    messageHistoryInOrder.forEach((xx, rowIndex) => {
      // this is the combination of data that makes a location and size of a chat item unique.
      keyMap[rowIndex] = [
        xx.event_guid,
        xx.time_utc,
        xx.first_in_group,
        xx.last_in_group,
        xx.transit_status
      ].join("_");
    });
    return keyMap;
  }
);

export const messageAtIndex = createSelector(
  [getMessageHistoryInOrder, propsIndex],
  (messages, index) => messages[index]
);

export const messageBeforeIndex = createSelector(
  [getMessageHistoryInOrder, propsIndex],
  (messages, index) => messages[index - 1]
);
export const messageAfterIndex = createSelector(
  [getMessageHistoryInOrder, propsIndex],
  (messages, index) => messages[index + 1]
);

export const getOtherUsersName = createSelector(
  [users, getOtherUsersIds],
  (allUsers, otherUsersIds) => {
    const fullname =
      allUsers[otherUsersIds[0]] && allUsers[otherUsersIds[0]].fullname;
    if (!fullname) {
      return "";
    }
    return fullname;
  }
);

export const getOtherUserOnlineStatus = createSelector(
  [users, getOtherUsersIds],
  (sUsers, userIds) => {
    const userStatus = sUsers[userIds[0]] && sUsers[userIds[0]].is_online;
    if (!userStatus) {
      return false;
    }
    return userStatus;
  }
);

export const getMyIdentity = createSelector(
  [users, getMyId],
  (allUsers, myId) => {
    const myIdentity = allUsers[myId];
    if (!myIdentity) {
      return {};
    }
    return myIdentity;
  }
);

export const amITheSessionOwner = createSelector(
  [getMyIdentity],
  (myIdentity) => {
    const isOwner = myIdentity.is_owner;
    return isOwner;
  }
);

export const getCurrentMessage = createSelector([currentMessage], (message) => {
  if (!message) {
    return "";
  }
  return message;
});

export const getMessageHistoryLength = createSelector(
  [getMessageHistoryInOrder],
  (historyInOrder) => {
    if (!historyInOrder) {
      return 0;
    }
    return historyInOrder.length;
  }
);

export const getMessageHistoryItem = createSelector(
  [messageBeforeIndex, messageAfterIndex, messageAtIndex, users, getMyId],
  (before, after, message, allUsers, myId) => {
    if (!message) {
      return {};
    }
    const newMessage = { ...message };

    newMessage.first_in_group =
      before === undefined || before.identity_guid !== message.identity_guid;
    newMessage.last_in_group =
      after === undefined || after.identity_guid !== message.identity_guid;

    newMessage.name =
      allUsers[message.identity_guid] &&
      allUsers[message.identity_guid].fullname;

    newMessage.is_me = message.identity_guid === myId;

    return newMessage;
  }
);

const startTimeUtc = (state) =>
  state.chatconnection && state.chatconnection.start_date_utc;

export const getStartTimeUtc = createSelector(
  [startTimeUtc],
  (startTime) => startTime || undefined
);

const isVideoSession = (state) =>
  state.chatconnection && state.chatconnection.session_modality === "Video";

export const getIsVideoSession = createSelector(
  [isVideoSession],
  (isVideo) => isVideo || false
);

export const getReadonlyMode = (state) => state.chatconnection.readonly_mode;
