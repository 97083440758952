/* eslint-disable @typescript-eslint/no-use-before-define */
import { useQuery } from "@tanstack/react-query";
import { apiGet } from "ieso-api-helper";
import { ICredentials, useCredentials } from "ieso-auth0";
import { useMemo } from "react";
import timelineApiUrls from "./apiUrls.ts";

export const useGetAudioUriApi = (
  sessionId: string,
  compositionGuid: string,
  enabled: boolean
): {
  status: "loading" | "error" | "success";
  data: string;
  error: unknown;
  isLoading: boolean;
} => {
  const credentials = useCredentials();

  const { isLoading, error, status, data } = useQuery({
    queryKey: ["get-audio-uri", sessionId, compositionGuid],
    enabled: !!sessionId && enabled,
    refetchOnWindowFocus: true,
    queryFn: () => getAudioUri(sessionId, compositionGuid, credentials)
  });

  return useMemo(
    () => ({
      status,
      data,
      error,
      isLoading
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, data]
  );
};

const getAudioUri = async (
  sessionId: string,
  compositionGuid: string,
  credentials: ICredentials
) => {
  const apiUrl = await timelineApiUrls.getAudioUri(sessionId, compositionGuid);

  return apiGet<{ sasUri }, string>(apiUrl, {
    ...credentials,
    responseTransformation: (response) => response.sasUri
  });
};
