import styled from "styled-components";
import { css } from "@styled-system/css";
import { linkButtonMixin } from "shared/mixins/buttons";
import Button from "AtlasChat/components/Button";

export const AttachmentButton = styled(Button)(
  linkButtonMixin,
  css({
    "&:first-child": {
      marginLeft: "-2px"
    }
  })
);
