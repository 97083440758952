import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { identityMiddleware } from "Identity/middleware";
import {
  reducer as chatconnection,
  middleware as chatconnectionmiddleware,
  NAME as chatconnectionName
} from "store/chatconnection";
import identity from "Identity/reducers";
import { createScrollingMiddleware, chatReducer as chat } from "Chat";
import {
  FeedbackUI as feedback,
  FeedbackStatus as feedbackStatus,
  tech_quality,
  overall_service_experience
} from "AppointmentFeedback";
import loading from "Loading/data/reducer";
import { reducer as chatendpoints } from "./chatendpoints";
import { reducer as telemetry } from "./telemetry";
import { loggingMiddleware } from "store/logging/middleware";

const { window } = global;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const UI = combineReducers({
  chat,
  feedback,
  feedbackStatus,
  tech_quality,
  overall_service_experience,
  loading
});

const dead_state = {};

if (global.__ieso_config.dead_state) {
  Object.keys(global.__ieso_config.dead_state).forEach((xx) => {
    dead_state[xx] = () => global.__ieso_config.dead_state[xx];
  });
}

const rootReducer = combineReducers(
  Object.assign(
    {
      chatendpoints,
      identity,
      UI,
      [chatconnectionName]: chatconnection,
      telemetry
    },
    {
      ...dead_state
    }
  )
);

const middlewares = [
  global.__ieso_config.log_redux_actions && createLogger({ collapsed: true }),
  thunkMiddleware,
  !global.__ieso_config.dont_login && identityMiddleware(),
  chatconnectionmiddleware.createMiddleware(),
  createScrollingMiddleware(),
  loggingMiddleware
  // appointmentStartDateMiddleware
].filter(Boolean);

export default function configureStore(preloadedState) {
  let store;
  if (document.location.pathname === "/silent_renew") {
    store = createStore(
      combineReducers({ identity, ...dead_state }),
      { telemetry: preloadedState },
      composeEnhancers()
    );
  } else {
    store = createStore(
      rootReducer,
      { telemetry: preloadedState },
      composeEnhancers(applyMiddleware(...middlewares))
    );
  }
  return store;
}
