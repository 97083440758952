/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { AuthenticationService } from "ieso-auth0";
import { Router, Route, Switch } from "react-router-dom";

import configureStore from "store/configureStore";
import { Page404, LoggedOut } from "screens";
import Appointment from "Appointment";
import Identity from "Identity";
import { Telemetry, getAppInsightsSessionId, useTelemetry } from "telemetry";
import { Banner } from "dark-matter";

import Loading from "Loading/Loading";
import history from "./utils/history";
import { minutesToTicks } from "./utils/time";
import {
  AppointmentDetails,
  useCreateAppointmentDetailsContext
} from "./AppointmentDetailsProvider";
import { IdleDetector } from "./IdleDetector/IdleDetector.tsx";
import AtlasChat from "./AtlasChat";
import { LOCAL_STORAGE_KEYS } from "./utils/localStorage";
import { useWindowLoad } from "./hooks/useWindowLoad";
import "./App.css";
import "darker-matter/build/assets/fonts/webfonts.css";

const App = ({ config }) => {
  const isDevEnv = process.env.NODE_ENV === "development";
  const telemetry = useTelemetry();

  const authConfig = useMemo(
    () => ({
      domain: config?.authentication?.domain,
      clientId: config?.authentication?.id,
      clientUri: config?.authentication?.uri,
      accessTokenLifetime: minutesToTicks(
        config?.authentication?.accessTokenLifetimeInMinutes
      ),
      loginOpts: {
        appState: {
          search: history.location.search,
          path: history.location.pathname
        },
        referrer: document.referrer,
        appInsightsSessionId: getAppInsightsSessionId()
      }
    }),
    []
  );

  const store = configureStore(telemetry);
  if (isDevEnv) {
    global.store = store;
  }

  const onRedirectCallback = (appState) => {
    let { pathname } = window.location;
    if (appState.path) {
      const storedUrl = localStorage.getItem(
        LOCAL_STORAGE_KEYS.postLogoutRedirectUrl
      );
      pathname =
        appState.path === "/" && storedUrl
          ? new URL(storedUrl).pathname
          : appState.path;
    }
    history.push(pathname);
  };

  return (
    <Provider store={store}>
      <>
        <Loading message="Loading &hellip;" fullscreen />
        <AuthenticationService
          provider="auth0"
          config={authConfig}
          logoutUrl={`${config.clientUri}/logged-out`}
          onRedirect={onRedirectCallback}
        >
          <Switch>
            <Route path="/callback">{null}</Route>
            <Route path="/logged-out" exact component={LoggedOut} />
            <Identity>
              <Switch>
                <Route exact path="/therapy/:guid" component={AtlasChat} />
                <Route
                  exact
                  path="/Appointment/:guid"
                  component={Appointment}
                />
                {/* // Hide the page not found while redirecting bug raised CAS-864 */}
                {/* <Route component={404} /> */}
                <Route exact path="/">
                  {null}
                </Route>
              </Switch>
            </Identity>
            <Route component={Page404} />
          </Switch>
          <IdleDetector timeout={config.idle_timer} />
        </AuthenticationService>
      </>
    </Provider>
  );
};

const ErrorBoundary = () => (
  <Banner appearance="critical">
    Something went wrong. Please try again later.
  </Banner>
);

const TelemetryAppWrapper = ({ config }) => {
  const telemetryConfig = config;
  const appointmentDetailsContext = useCreateAppointmentDetailsContext();
  useWindowLoad();

  return (
    <Router history={history}>
      <Telemetry config={telemetryConfig} fallbackComponent={ErrorBoundary}>
        <AppointmentDetails.Provider value={appointmentDetailsContext}>
          <App config={config} />
        </AppointmentDetails.Provider>
      </Telemetry>
    </Router>
  );
};

export default TelemetryAppWrapper;
