import { IMessage, IParticipant } from "AtlasChat/types";
import { Box, DateUtils, Spinner, Utterance } from "darker-matter";
import React, { useState } from "react";
import parser from "html-react-parser";
import { showToast } from "AtlasChat/components/Toast/Toast.tsx";
import {
  useAddDeletedEventApi,
  useRemoveDeletedEventApi
} from "AtlasChat/api/deletedEvents/useDeletedEventsApi.ts";
import { ReactComponent as DeleteIcon } from "shared/icons/delete.svg";
import useAttachments from "../../ChatTextbox/hooks/useAttachments.ts";
import MessageAttachment from "./MessageAttachment.tsx";
import { StyledBubble } from "../styles.ts";
import { useModal } from "../ModalContext.tsx";

interface IMessageProps {
  message: IMessage;
  nextMessage: IMessage | null;
  isSessionOwner: boolean;
  sessionId: string;
  allParticipants: IParticipant[];
  isSysAdmin: boolean;
}

const REMOVED_MESSAGE = "This message was deleted";

const Message: React.FC<IMessageProps> = ({
  message,
  nextMessage,
  sessionId,
  isSessionOwner,
  allParticipants,
  isSysAdmin
}) => {
  const { downloadAttachment } = useAttachments(sessionId);
  const [isRemoved, setIsRemoved] = useState<boolean>(
    message.deletedUtc != null
  );
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const { openModal, closeModal } = useModal();

  const removeDeletedEvent = useRemoveDeletedEventApi(message.event_id);

  const handleDeleteSuccess = () => {
    setIsRemoved(true);
    showToast({
      appearance: "success",
      message: "Utterance removed",
      buttonText: "Undo",
      onClick: () =>
        removeDeletedEvent.mutateAsync().then(() => setIsRemoved(false))
    });
  };

  const handleDeleteError = () => {
    showToast({
      appearance: "error",
      message: "Unable to remove utterance"
    });
  };

  const addDeletedEvent = useAddDeletedEventApi(
    message.event_id,
    handleDeleteSuccess,
    handleDeleteError
  );

  const handleClick = () => {
    addDeletedEvent.mutateAsync();
    closeModal();
  };

  const messageSender = allParticipants.find(
    (p) => p.identity_guid === message.identity_guid
  );
  const appearance = messageSender.is_owner ? "therapist" : "member";
  const bubbleAppearance = messageSender.is_owner ? "default" : "me";
  const orientation =
    (isSessionOwner === false && !messageSender.is_owner) ||
    (isSessionOwner !== false && messageSender.is_owner)
      ? "right"
      : "left";
  const author = messageSender.fullname;
  const timestamp = DateUtils.formatTime(new Date(message.time_utc), "short");
  const hideAvatar =
    nextMessage?.identity_guid === message.identity_guid &&
    nextMessage?.event_type === "Sentence";
  const messageContent = isRemoved
    ? REMOVED_MESSAGE
    : parser(message.sanitizedContent);
  const isEmptyMessageContent =
    Array.isArray(messageContent) && messageContent.length === 0;

  const handleKeyDown = (event: React.KeyboardEvent<SVGSVGElement>) => {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault();
      openModal({ messageContent, onProceed: handleClick });
    } else if (event.key === "Tab" && event.shiftKey) {
      event.preventDefault();
      setShowDeleteButton(false);
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    if (
      event.relatedTarget &&
      (event.relatedTarget as HTMLElement).id === "delete-message"
    ) {
      return;
    }
    setShowDeleteButton(false);
  };

  return (
    <React.Fragment key={message.event_id}>
      <Utterance
        appearance={appearance}
        orientation={orientation}
        status={message.transit_status || "sent"}
        author={author}
        hideAvatar={hideAvatar}
        timestamp={message.event_type !== "typing" ? timestamp : null}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={orientation === "right" ? "flex-end" : "flex-start"}
        >
          {!isEmptyMessageContent && (
            <Box flexDirection="row" display="flex">
              <Box>
                <StyledBubble
                  tabIndex={
                    messageSender.is_owner && !isRemoved ? 0 : undefined
                  }
                  onFocus={() => {
                    setShowDeleteButton(true);
                  }}
                  onBlur={handleBlur}
                  orientation={orientation}
                  appearance={bubbleAppearance}
                >
                  {message.event_type === "typing" ? (
                    <Spinner />
                  ) : (
                    <>{messageContent}</>
                  )}
                </StyledBubble>
              </Box>
              <Box>
                {isSysAdmin &&
                  messageSender.is_owner &&
                  !isRemoved &&
                  showDeleteButton && (
                    <DeleteIcon
                      id="delete-message"
                      tabIndex={0}
                      style={{ marginTop: "14px", marginLeft: "6px" }}
                      onClick={() =>
                        openModal({ messageContent, onProceed: handleClick })
                      }
                      onBlur={() => setShowDeleteButton(false)}
                      onKeyDown={handleKeyDown}
                    />
                  )}
              </Box>
            </Box>
          )}
          {!!message.attachments?.length && (
            <Box mt={2}>
              {message.attachments?.map((a) => (
                <MessageAttachment
                  key={a.id}
                  attachment={a}
                  onDownloadAttachment={downloadAttachment}
                />
              ))}
            </Box>
          )}
        </Box>
      </Utterance>
    </React.Fragment>
  );
};

function areEqual(prevProps: IMessageProps, nextProps: IMessageProps) {
  return (
    prevProps.message.event_guid === nextProps.message.event_guid &&
    prevProps.nextMessage?.event_guid === nextProps.nextMessage?.event_guid &&
    prevProps.isSessionOwner === nextProps.isSessionOwner &&
    prevProps.sessionId === nextProps.sessionId &&
    prevProps.message.transit_status === nextProps.message.transit_status
  );
}

export default React.memo(Message, areEqual);
