/* eslint react/prop-types: 0 */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useAuthenticationService } from "ieso-auth0";
import { USER_FOUND } from "redux-oidc";
import { withRouter } from "react-router";

import { setLoading } from "Loading/data/actions";
import { getUser } from "Identity/selectors";
import { getLoading } from "Loading/data/selectors";

const Identity = (props) => {
  const {
    children,
    setReduxLoading,
    setReduxUser,
    userFromState,
    uiIsLoading
  } = props;
  const auth = useAuthenticationService();

  const setUserInRedux = React.useCallback(
    async (name, token) => {
      setReduxUser(name, await token);
    },
    [setReduxUser]
  );

  useEffect(() => {
    if (auth.idToken && !userFromState && !uiIsLoading) {
      setUserInRedux(auth.user.name, auth.idToken.__raw);
    }
  }, [
    auth,
    auth.user,
    setUserInRedux,
    setReduxLoading,
    userFromState,
    uiIsLoading
  ]);

  if (auth.authenticated !== "pending" && !auth.authenticated) {
    auth.login();
  }

  if (!auth.user) {
    return null;
  }

  setReduxLoading(false);
  return children;
};

const mapStateToProps = (state) => {
  const userFromState = getUser(state);
  const uiIsLoading = getLoading(state);
  return {
    userFromState,
    uiIsLoading
  };
};
const mapDispatchToProps = (dispatch) => ({
  setReduxLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setReduxUser: (user, token) =>
    dispatch({
      type: USER_FOUND,
      payload: {
        access_token: token,
        // I think this can be id token but need to dig
        id_token: token,
        name: user
      }
    })
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Identity)
);
