/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC, useState } from "react";
import { Card, DateTime, Text } from "darker-matter";
import styled from "styled-components";
import { IRecordingTimelineEvent } from "AtlasChat/types";
import { useGetAudioUriApi } from "AtlasChat/api/timeline/useGetAudioUri";
import { buttonMixin } from "shared/mixins/buttons";
import { Button } from "AtlasChat/components/Button";

interface IAudioRecordingProps {
  compositionGuid: IRecordingTimelineEvent["compositionGuid"];
  compositionStatus: IRecordingTimelineEvent["compositionStatus"];
  errorMessage: IRecordingTimelineEvent["errorMessage"];
  eventGuid: IRecordingTimelineEvent["event_guid"];
  timeUtc: IRecordingTimelineEvent["time_utc"];
  sessionId: string;
}

const AudioRecording: FC<IAudioRecordingProps> = ({
  compositionGuid,
  compositionStatus,
  errorMessage,
  eventGuid,
  sessionId,
  timeUtc
}) => {
  const [showAudio, setShowAudio] = useState(false);
  const { data: audioSasUri } = useGetAudioUriApi(
    sessionId,
    compositionGuid,
    showAudio && compositionStatus === "AvailableAndDeletedFromSource"
  );

  const recordingViewData = getRecordingViewData(
    compositionStatus,
    errorMessage
  );

  return (
    <Card key={eventGuid} my={4}>
      <Text mb={2}>{recordingViewData.text}</Text>
      {recordingViewData.showAudioPlayer && (
        <>
          {showAudio ? (
            <audio controls src={audioSasUri} />
          ) : (
            <AudioButton
              mb={2}
              onClick={() => setShowAudio(true)}
              ariaLabel="Access audio"
              ariaDescription="Access audio player"
            >
              Show audio
            </AudioButton>
          )}
        </>
      )}
      <DateTime
        appearance="muted"
        textStyle="small"
        value={new Date(timeUtc)}
        format="mediumDay"
        time
        display="block"
      />
    </Card>
  );
};

const AudioButton = styled(Button)`
  ${buttonMixin("neutral")}
`;

function getErrorMessage(errorMessage: string) {
  switch (errorMessage) {
    case "There was only one audio stream.":
      return "Session audio recording is not available because there was only one stream.";
    default: {
      return errorMessage;
    }
  }
}

function getRecordingViewData(
  compositionStatus: string,
  errorMessage?: string
) {
  if (errorMessage) {
    return {
      text: getErrorMessage(errorMessage)
    };
  }

  if (compositionStatus === "AvailableAndDeletedFromSource") {
    return {
      text: "Session audio recording is ready:",
      showAudioPlayer: true
    };
  }

  return {
    text: "Session audio recording is processing and will be available soon."
  };
}

export default React.memo(AudioRecording);
