import { createUserManager } from "redux-oidc";

const { window } = global;

const userManagerConfig = {
  client_id: "",
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }/callback`,
  response_type: "token id_token",
  // scope: 'openid profile email phone authroles betatester offline',
  scope: "openid profile",
  authority: "",
  silent_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ""}/silent_renew`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true
};

const userManager = createUserManager(userManagerConfig);
export default userManager;
