import { USER_EXPIRED, USER_SIGNED_OUT, SILENT_RENEW_ERROR } from "redux-oidc";

import userManager from "utils/userManager";
import { SIGN_OUT_USER } from "./actions";

// Remove the current user from any storage, clear any stale state (with previous redirectURL),
// redirect to login page in Identity Service. Request to Identity Service will contain state parameter which corresponds to state in local storage.
// This is where the redirectURL will be stored and used in CallbackPage.
const redirectToSignIn = () => {
  const currentUrl = document.location.pathname;
  userManager.removeUser().then(() => {
    userManager.clearStaleState();
    userManager.signinRedirect({
      data: { redirectURL: currentUrl }
    });
  });
};

export function identityMiddleware() {
  return () => (next) => (action) => {
    next(action);
    const currentURL = document.location.pathname;
    switch (action.type) {
      case SIGN_OUT_USER:
        // Redirects the user to the end session endpoint in Identity Server.
        // TODO: signout end session endpoint in OIDC redirects to localhost:4000
        userManager.signoutRedirect({
          data: { redirectURL: currentURL }
        });
        break;

      case USER_EXPIRED:
        // Fires when there's no user in local storage at startup or user expires.
        if (currentURL !== "/callback" && currentURL !== "/silent_renew") {
          redirectToSignIn();
        }
        break;

      case USER_SIGNED_OUT:
        redirectToSignIn();
        break;

      case SILENT_RENEW_ERROR:
        // Fires when there's an error in token refresh. This results in oidc clearing the user so without this redirect
        // We would just get an authentication error message and application would hang for ~1 min.
        redirectToSignIn();
        break;

      default:
    }
  };
}
